*{
    color: rgb(255, 255, 255);
    font-family: 'Libre Baskerville', Arial, Helvetica, sans-serif;
    scroll-behavior: smooth;
}

html body {
    margin: 0;
    background-color: #1b1b1b;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

/* Navbar Section */

.menu {
    height: 75px;
    width: 100%;
    background-color:rgba(0, 0, 0, 0.904);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.menu-bar {
    font-size: xx-large;
    margin-top: 15px;
}

.navbar-links {
    padding-top: 7px;
}

nav {
    display: flex;
    position: fixed;
    justify-content: space-between;
    padding-left: 7.5%;
    padding-right: 10%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

nav ul li {
    list-style-type: none;
    display: inline-block;
    padding: 10px 20px;
    z-index: 999;
}

nav ul li a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: x-large;
    z-index: 999;
}

nav ul li a:hover {
    color: #ea1538;
    transition: .3s;
}

/* Home Page Section */

section {
    position: relative;
    display: grid;
    width: 0 auto;
    place-items: center;
    align-content: center;
    min-height: 100vh;
    padding: 10rem 2rem;
}

.full-stack-container {
    margin-left: -1200px;
    margin-top: -650px;
}

.intro-name {
    display: flex;
    font-size: xx-large;
    justify-content: left;
}

.full-stack-web-developer {
    display: flex;
    padding-top: 25px;
    padding-bottom: 25px;
} 

.short-motivation {
    max-width: 650px;
    padding-bottom: 25px;
    font-size: 23px;
} 

.button {
    text-decoration: none;
    margin-left: 13rem;
    width: 150px;
    height: 50px;
    justify-content: center;
    display: flex;
    position: relative;
    align-items: center;
    background-color: #1b1b1b;
    padding: 8x 20px;
    border-radius: 50px;
    border: solid white;
    font-weight: bold;
    color: white;
    box-shadow: 8px 10px 6px 4px rgba(0,0,0,0.75);
-webkit-box-shadow: 8px 10px 6px 4px rgba(0,0,0,0.75);
-moz-box-shadow: 8px 10px 6px 4px rgba(0,0,0,0.75);
}

.button:hover {
    color: #ea1538;
    background-color: white;
    transition: 1s; 
}

.tech-stack-container {
    display: flex;
    justify-content: flex-start;
    margin-left: -1050px;
    margin-top: -150px;
}

.about-me-text {
    text-align: center;
    padding-top: 345px;
    max-width: 328px;
}

.self-image {
    display: flex;
    width: 300px;
    height: 300px;
    object-fit: cover;
    background-color: rgb(190, 186, 186);
    border-radius: 200px;
    border: solid;
    border-width: 4px;
    border-color: #000000;
    position: relative;
    z-index: 0;
    margin-top: -575px;
    margin-left: 1425px;
    margin-right: 150px;
    -webkit-box-shadow: 22px 14px 9px -2px rgba(0,0,0,0.77);
    -moz-box-shadow: 22px 14px 9px -2px rgba(0,0,0,0.77);
    box-shadow: 22px 14px 9px -2px rgba(0,0,0,0.77);
}

.self-image-grey-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid;
    border-width: 4px;
    border-color: #000000;
    width: 350px;
    height: 600px;
    background-color: #0e0e0ef1;
    border-radius: 30px;
    z-index: 0;
    position: relative;
    margin-left: 1400px;
    margin-top: -450px;
    -webkit-box-shadow: 22px 14px 9px -2px rgba(0,0,0,0.77);
    -moz-box-shadow: 22px 14px 9px -2px rgba(0,0,0,0.77);
    box-shadow: 22px 14px 9px -2px rgba(0,0,0,0.77);
}

.cherry-blossum-img {
    display: flex;
    border: solid;
    border-width: 4px;
    border-color: #000000;
    margin-right: -600px;
    border-radius: 30px;
    z-index: 0;
    -webkit-box-shadow: 22px 14px 9px -2px rgba(0,0,0,0.77);
    -moz-box-shadow: 22px 14px 9px -2px rgba(0,0,0,0.77);
    box-shadow: 22px 14px 9px -2px rgba(0,0,0,0.77);
}


/* My Projects Section */

.my-projects-header {
    position: sticky;
    text-align: center;
}

.my-projects{ 
    display: flex;
    margin-top: 4rem;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid;
    background-color: rgb(13, 13, 13);
    border-width: 2px;
    border-color: red;
    width: 1600px;
    height: 550px;
    border-radius: 30px;
    box-shadow: 8px 10px 6px 4px rgba(0,0,0,0.75);
    -webkit-box-shadow: 8px 10px 6px 4px rgba(0,0,0,0.75);
    -moz-box-shadow: 8px 10px 6px 4px rgba(0,0,0,0.75);

}

.view-project-button {
    text-decoration: none;
    margin-left: 15px;
    width: 150px;
    height: 50px;
    justify-content: center;
    display: flex;
    position: relative;
    align-items: center;
    background-color: #1b1b1b;
    padding: 8x 20px;
    border-radius: 50px;
    border: solid white;
    font-weight: bold;
    color: white;
    box-shadow: 8px 10px 6px 4px rgba(0,0,0,0.75);
    -webkit-box-shadow: 8px 10px 6px 4px rgba(0,0,0,0.75);
    -moz-box-shadow: 8px 10px 6px 4px rgba(0,0,0,0.75);
}

.view-project-button:hover {
    color: #ea1538;
    background-color: white;
    transition: 1s; 
}

.pokellection-project-container {
    padding-right: 35px;
}

.pokellection-project{
    padding-bottom: 15px;
}

.view-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.skills-header{
    margin-bottom: 5rem;
}

.skills-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 3rem;
}

.skills-container img {
    font-size: 10rem;
}

.skills-container div {
    height: 280px;
    width: 280px;
    border: solid;
    border-color: red;
    background: rgb(13, 13, 13);
    padding: 3rem 2rem 4rem;
    border-radius: 2rem;
    text-align: center;
    transition: .3s ease;
    box-shadow: 8px 10px 6px 4px rgba(0,0,0,0.75);
    -webkit-box-shadow: 8px 10px 6px 4px rgba(0,0,0,0.75);
    -moz-box-shadow: 8px 10px 6px 4px rgba(0,0,0,0.75);
}
.skills-container div:hover {
    color: #ea1538;
    border-color: white;
    transition: 1s; 
    transform: scale(1.02);
}

.skills-section h2 {
    margin-bottom: 5rem;
    font-size: 5rem;
    display: flex;
    justify-content: center;
}
.skills-container h3 {
    font-size: 2rem;
    margin-top: 2rem;
}


/* Contact Form Styling */

.contact-me-header {
    text-align: center;
}

.form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid;
    background-color: rgb(21, 21, 21);
    border-width: 2px;
    border-color: red;
    width: 800px;
    margin-left: 29rem;
    margin-right: 27rem;
    max-height: 650px;
    border-radius: 30px;
    box-shadow: 8px 10px 6px 4px rgba(0,0,0,0.75);
    -webkit-box-shadow: 8px 10px 6px 4px rgba(0,0,0,0.75);
    -moz-box-shadow: 8px 10px 6px 4px rgba(0,0,0,0.75);
}

form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 30px;
    margin-top: 100px;
.label {
        margin-top: 2rem;
}

.textarea {
    color: black;
    width: 412px;
    padding-top: 5px;
    border-radius: 16px;
    border-color: red;
    box-shadow: 8px 10px 6px 4px rgba(0,0,0,0.75);
    -webkit-box-shadow: 8px 10px 6px 4px rgba(0,0,0,0.75);
    -moz-box-shadow: 8px 10px 6px 4px rgba(0,0,0,0.75);
}

.input {
    margin-top: 3rem;
    margin-bottom: 10rem;
    cursor: pointer;
    text-decoration: none;
    margin-left: 15px;
    width: 150px;
    height: 50px;
    justify-content: center;
    display: flex;
    position: relative;
    align-items: center;
    background-color: #1b1b1b;
    padding: 8x 20px;
    border-radius: 50px;
    border: solid white;
    font-weight: bold;
    color: white;
    box-shadow: 8px 10px 6px 4px rgba(0,0,0,0.75);
    -webkit-box-shadow: 8px 10px 6px 4px rgba(0,0,0,0.75);
    -moz-box-shadow: 8px 10px 6px 4px rgba(0,0,0,0.75);
    }

.input:hover {
        color: #ea1538;
        background-color: white;
        transition: 1s; 
    }
}

/* Footer Section */

.footer-container {
    height: 150px;
    width: 100%;
    background-color: rgb(8, 8, 8);
}

.social-header {
    padding-top: 30px;
    margin-left: 45%;
    font-size: large;
}

.social-links {
    display: flex;
    margin-left: 46.5%;
}

.github-logo-container img{
    margin-right: 15px;
}

.github-logo-container img:hover{
    background-color: rgb(0, 0, 0);
    transition: 1s; 
}

.linkedin-logo-container img:hover{
    background-color: rgb(0, 0, 0);
    transition: 1s; 
}